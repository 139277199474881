import { ProduitData } from '@innedit/innedit';
import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';

import Button from '../../components/Button';
import TemplatePublic from '../../templates/public';

const PageBoutique: FC<PageProps> = ({ params: { espaceId } }) => {
  const [produits, setProduits] = useState<DocumentType<ProduitType>[]>();

  useEffect(() => {
    let isMounted = true;
    const produitData = new ProduitData({ espaceId });

    const unsub = produitData.watch(
      newDocs => {
        if (isMounted) {
          setProduits(newDocs);
        }
      },
      {
        limit: 20,
      },
    );

    return () => {
      isMounted = false;

      if (unsub) {
        unsub();
      }
    };
  }, [espaceId]);

  return (
    <TemplatePublic espaceId={espaceId}>
      {produits && (
        <ul>
          {produits.map(produit => (
            <li key={produit.id}>
              <Button to={`/${espaceId}/${produit.id}/`} variant="link">
                {produit.name}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </TemplatePublic>
  );
};
export default PageBoutique;
